/* Add this CSS in your App.css or equivalent stylesheet */
@import url('https://fonts.googleapis.com/css2?family=Pixelify+Sans&display=swap');

body, html {
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #46677c; /* Light grey background */
}

#app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #46677c; /* White background for the app container */
}

input[type="text"] {
  text-align: center;
  width: 30vw;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  font-family: 'Pixelify Sans', sans-serif;
}

button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  background-color: #6e8da0; /* Blue background */
  color: white;
  cursor: pointer;
}

button:hover {
  background-color: #1e9deb; /* Darker blue on hover */
}

img {
  margin-top: 20px;
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

#logo {
  width: 80px;
  height: 80px;
}

#logocontianer {
  display: flex;
  position: absolute;
  top: 20px;
  justify-content: center;
  align-items: center;
}

#msgcontianer {
  position: absolute;
  margin-top: 130px;
  font-size: 30px;
  color: white;
  font-family: 'Pixelify Sans', sans-serif;
}